import React from "react"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import { injectIntl } from "gatsby-plugin-intl"

const NotFoundPage = ({ intl }) => (
  <PageLayout>
    <SEO title={intl.formatMessage({ id: "not-found" })} />
    <h1>{intl.formatMessage({ id: "not-found-title" })}</h1>
    <p>{intl.formatMessage({ id: "not-found-text" })}</p>
  </PageLayout>
)

export default injectIntl(NotFoundPage)
